import React from 'react'

import Head from './head/Head.jsx'
import Case from './interne/Case.jsx'
import './help.css'
import { FIRST } from './data'
import {OrderList} from '../trie/Trie'

let language=JSON.parse(localStorage.getItem('lang')) || {lang: 'fr', flag: 0}
const Help = () => {

    // console.log(OrderList(language))

    return (
        <>
            <Head />
            <div className="helpList">
                <Case route={FIRST.route} />
                {/* {FIRST.map(item=><Case key={item.id} title={item.title} description={item.description} route={item.route} img={item.img}  />)} */}
            </div>
        </>
    )
}

export default Help;
