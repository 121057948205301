import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Help from './components/Help/Help.jsx'
import Description from './components/description/Description'
import resources from './components/lang/lang';


import './App.css';


let language=JSON.parse(localStorage.getItem('lang')) || {lang: 'en', flag: 0}

i18n.use(initReactI18next)
    .init({
    resources,  
    lng: language.lang,
    fallbackLng: language.lang,
    interpolation: { escapeValue: false }
});

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path='/' exact component={Help} />
        <Route path='/Description' exact component={Description} />
        <Redirect to='/' />
      </Switch>         
    </div>
  );
}

export default App;
