import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'

// import { FaBook } from 'react-icons/fa'
import imag1 from '../assets/logo-kyc.svg'
import buy from '../assets/buy.svg'
import sell from '../assets/blockchain.svg'
import depot from '../assets/send.svg'
import logo from '../assets/enseigne.png'

import './description.css'

import Descript1 from './descript1/Descript1'
import Descript2 from './descript2/Descript2'
import Descript3 from './descript3/Descript3'
import Descript4 from './descript4/Descript4'
import External from './assets/external-link.svg'
import Lang from '../ressource/Lang'


let videoList={
    en: ["https://www.youtube.com/watch?v=CnOX17_1_b0", "https://www.youtube.com/watch?v=CtR3iH74Y-o", "https://www.youtube.com/watch?v=EdtT-tHeQMA", "https://www.youtube.com/watch?v=CtR3iH74Y-o", "" ],
    fr: ["https://www.youtube.com/watch?v=CnOX17_1_b0", "https://www.youtube.com/watch?v=CtR3iH74Y-o", "https://www.youtube.com/watch?v=BhJZ8qRzxWg", "https://www.youtube.com/watch?v=CtR3iH74Y-o", "" ]
}

function Description() {
    const { t } = useTranslation()
    const { pathname, hash, key }=window.location
    console.log(pathname,hash,key)
    const handleMenu = () => {
        console.log('first toogle ')
        const menu = document.getElementById('liste');

        document.getElementById('liste').classList.toggle('see');
        document.getElementById('liste').classList.toggle('description-menu');
        
    }

    useEffect(() => {
        console.log("use effect on")
        if(hash==='') window.scrollTo(0, 0)
        else {
            let id=hash.replace('#', '')
            let element=document.getElementById(id)
            if(element) element.scrollIntoView()
        }

    }, [ pathname, hash, key ])

    let lang=JSON.parse(localStorage.getItem("lang")).lang
    // let lang = 'en'
    return (
        <div  className="description">
            <div className="description-head">
                <div className="help-bd">
                    {/* entete de la page en rouge */}
                    <div className='hel'>
                        <div><a href='http://ipercash.io' className="help-one" ><p><img className='logo' src={logo} /></p></a></div>
                        <div><a href='http://ipercash.io'  className="help-two"> <p  ><img className="svg1" src={External} alt='link'/>{t('descripTitle')} </p></a></div>
                    </div>
                    <div className='second'>
                        <Lang   />
                        <div onClick={handleMenu} id="toog" className="toogle">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="description-body">
                <div className="description-menu" id='liste'>
                    <ul className="list-ul">
                        <li className="list"> 
                            <a href="#dsc-about">
                                <span className="icon"><i>&ensp;<img src={imag1} width='35px' alt='logo' /></i></span>
                                <span className="title">{t('descriptSous1')} </span>
                            </a>
                        </li>
                        <li className="list"> 
                            <a href="#dsc-move">
                                <span className="icon"><i>&ensp;<img src={sell} width='30px' alt='logo' /></i></span>
                                <span className="title">{t('descriptSous2')}  {/*{vente votre crypto*/}</span>
                            </a>
                        </li>
                        <li className="list"> 
                            <a href="#dsc-buy">
                                <span className="icon"><i>&ensp;<img src={buy} width='30px' alt='logo' /></i></span>
                                <span className="title">{t('descriptSous3')} {/*achat de la crypto*/} </span>
                            </a>
                        </li>
                        <li className="list"> 
                            <a href="#dsc-do">
                                <span className="icon"><i>&ensp;<img src={depot} width='45px' alt='logo' /></i></span>
                                <span className="title">{t('descriptSous4')} {/*un depot Mobile Money */}</span>
                            </a>
                        </li>   
                    </ul>
                </div>
                <div className="description-content">
                    {/*--section--about--*/}
                    <div className='content-about' id="dsc-about">
                        <h1 className='title2' style={{textAlign:"center",fontSize:"45px"}}>{t('descripTitleBody1')} </h1>
                        <p className='title3'><span className='title-color'>{t('descripTitleBody2')} </span> </p>
                        <p style={{fontSize:"16px"}}>{t('descriptAjout')}</p>
                        <div className='player'><ReactPlayer url={videoList[lang][0]} controls  /></div>
                    </div>
                    
                    <Descript1  video={videoList} lang={lang} />
                    <Descript2  video={videoList} lang={lang} />
                    <Descript3  video={videoList} lang={lang} /> 
                    {/* <Descript4 video={videoList} lang={lang} /> */}
                    <hr width='90%' height="2px" color="#ee5253"/>
                    </div>
            </div>
        </div>
    )
}

export default Description
