import React from 'react'
import { useTranslation } from 'react-i18next'

import './head.css'
import svg1 from '../assets/img/external-link.svg' 
import searche from '../assets/img/search.svg'
import Lang from '../../ressource/Lang'

const Head = () => {
    const { t } = useTranslation();
    return (
        <div className="help-hd">
            {/* entete de la page en rouge */}
            <div className="help-Go">
                <a href='http://ipercash.io'><span className="help-Logo" style={{color:'white'}} >IPERCASH</span></a>
                <a href='http://ipercash.io'> <span className="help-Right" style={{color:'white'}}><img className="svg1" src={svg1} alt='link'/>{t('headHelp')} IPERCash</span></a>
                <div className="banner">
                    <Lang />
                </div>
            </div>
            <div className="Help-Mot1">
                <p>{t('support')}.</p>
            </div>
            <form id="form" metho="post" >
                <div className="onglet-recherche">
                    <span className="btnh-imag"><img className="img-search" alt="search" src={searche} /></span>
                    <input className="help-Search" type="text" id="search" placeholder={t('support1')} autoComplete="off"/> 
                </div>
            </form>
        </div>
    )
}

export default Head
