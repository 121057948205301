const english={ translation : {
    

        coverModalTitle: 'TRANSACTION IN PROGRESS',
        coverModalSous1: 'The identifier of your operation is :',
        coverModalSous2: 'Use it for any claim',
        coverModalSous3: 'Success',
        coverModalSous4: "Operation completed",
        coverModalSous5: "Finalize the operation on the widget",

        //sellrypto 
        sellCrypto:'SELL CRYPTO',
        sellCrypto2:'Crypto Rate',
        sellCrypto3:'Coindesk',
        sellCrypto4:' Provided by',
        sellCrypto5:'Sell',
        sellCrypto6:"Sender Wallet Address",
        sellCrypto7:'"This field is require"',
        sellCrypto8:"Amount in XAF",
        sellCrypto9:"The value should be between 3000 and 35000 XAF" ,
        sellCrypto10:"Amount in BTC",
        sellCrypto11:"the min value is 0.00033",
        sellCrypto12:"Fees in XAF",
        sellCrypto13:"Receiver Mobile Phone Number",
        sellCrypto14: "Invalid number",
        sellCrypto15:"Confirm Mobile Phone",
        sellCrypto16:"Should match to phone number",
        

        //sellModal
        sellModal:'Transaction confirmed',
        sellModal1:'Transaction fund but not confirmed yet waiting for confirmation',
        sellModal2:'Try with another amount or try again in',
        sellModal3:'Cancel',
        sellModal4:'OPERATION SUMMARY',
        sellModal5:'Change',
        sellModal6:'to',
        sellModal7:'First you need to send exactely',
        sellModal8:'Then click on "I have sent " button to continue.',
        sellModal9:'Scan QrCode to get wallet address',
        sellModal10:'Here is your operation identifier',
        sellModal11:'cancel',
        sellModal12:'I have sent',
        sellModal13:'Send',
        sellModal14:'From',
        sellModal15:'Receive',
        sellModal16:'to',
        sellModal17:'Fees',
        sellModal18:'Confirm',
        sellModal19:'We could not send money to your mobile money',

        // buycryptoCard
        buyCryptoCardTitle: 'BUY CRYPTO WITH CREDIT CARD ',
        buyCryptoCardSous1:'Crypto Rate',
        buyCryptoCardSous2:'Coindesk',
        buyCryptoCardSous3:'Provided by',
        buyCryptoCardSous4:'Amount in XAF',
        buyCryptoCardSous5:"Amount in EUR",
        buyCryptoCardSous6:"Amount in BTC",
        buyCryptoCardSous7:"Crypto Wallet Address",
        buyCryptoCardSous8:"Waiting for rate ...",
        buyCryptoCardSous9:"Buy",
        buyCryptoCardSous10:"The min value is 16500 XAF",
        buyCryptoCardSous11:"The min value is 25 EUR",
        buyCryptoCardSous12:"The min value is 0.00033",
        buyCryptoCardSous13:"This field is require" ,

          // buycryptoMobile
          buyCryptoMobileTitle: 'Checking wallet',
          buyCryptoMobileSous1:'Your wallet address',
          buyCryptoMobileSous2:'Get back',
          buyCryptoMobileSous3:'BUY CRYPTO WITH MOBILE MONEY',
          buyCryptoMobileSous4:'Coindesk ',
          buyCryptoMobileSous5:'Crypto Rate',
          buyCryptoMobileSous6:'Provided by ',
          buyCryptoMobileSous7:"Amount in XAF",
          buyCryptoMobileSous8:"The value should be between 10000 and 35000 XAF",
          buyCryptoMobileSous9:"Amount in EUR",
          buyCryptoMobileSous10:"The min value is 15.26 EUR",
          buyCryptoMobileSous11:"Amount in BTC",
          buyCryptoMobileSous12:"The min value is 0.00033" ,
          buyCryptoMobileSous13:"Receiver Mobile Phone Number",
          buyCryptoMobileSous14:"Invalid number",
          buyCryptoMobileSous15:"Confirm Mobile Phone",
          buyCryptoMobileSous16:"Should match to phone number",
          buyCryptoMobileSous17:"Crypto Wallet Address",
          buyCryptoMobileSous18:"This field is require",
          buyCryptoMobileSous19:"Waiting for rate ...", 
          buyCryptoMobileSous20:"Buy", 
          buyCryptoMobileSous21:"Paste your wallet address here", 

          //chooses 
          choosesTitle: 'Choose Your Payment Method',
          chosesSous1: 'Mobile money',
          chosesSous2: 'Available in CEMAC and UMOA zones',
          chosesSous3: 'Available anywhere in the world',
          chosesSous4: 'Credit card',

          //comingSoon
          comingSoonTitle: 'THIS FEATURE IS COMING SOON',
          comingSoonSous2: 'Join waiting list',
          comingSoonSous3: 'Notify me',
          comingSoonSous4: 'Back to home page',

          //footer
          footerSous1: 'Copyright IPERCash. All Rights Reserved',
          footerSous2: 'Designed By',
          footerSous3: 'IPERCash',
          footerSous4: 'Terms & Condition ',
          footerSous5: 'Confidentiality',
          footerSous6: 'AML_Policy',

          //form
          formSous1: 'FULL NAME',
          formSous2: 'E-MAIL',
          formSous3: 'MESSAGE',
          formSous4: 'Send Message',
          formSous5: 'NAME',

          //Countries
          Coubtries1: 'cameroon',
          Coubtries2: 'Senegal',
          Coubtries3: 'Ivory-Coast',
          Coubtries4: 'Mali',
          Coubtries5: 'Burkina',
          Coubtries6: 'Guinea',

          //Login
          LoginTitle:'Sign in',
          LoginSous1:' Sign in if you are already registred or create an account. ',
          LoginSous2:'Your email is required!',
          LoginSous3:'Email',
          LoginSous4:"Password",
          LoginSous5:'Remember me',
          LoginSous6:'Login',
          LoginSous7:'Create account',
          LoginSous8:'Passord should have at least heigth characters with one number',
          LoginSous9:"Forgot Password",
          LoginSous11:' Sign in if you are already registred or create an account.' ,
          //SignUp
          SignUpTitle:'Register now',
          SignUpSous1:'If you do not have an account yet, please register',
          SignUpSous2:'Remember me',
          SignUpSous3:'Forgot Password',
          SignUpSous4:'Signup',
          SignUpSous5:"Full Name",
          SignUpSous6:"Email",
          SignUpSous7:"Phone",
          SignUpSous8:"Password",
          SignUpSous9:"Confirm Password" ,
          SignUpSous10:"Login to IPERCash" ,
          SignUpSous11:"Checking Your Email!" ,
          SignUpSous12:"Dear Customer, Help Us Help You!" ,
          SignUpSous13:"Dear Client" ,
          SignUpSous14:"An e-mail has been sent to you, please check in the e-mail box and in spam. Otherwise, send the email again, for the validation of your registration!" ,
          SignUpSous15:"Resend Mail" ,
          SignUpSous16:'Passord should have at least heigth characters with one number' ,
          SignUpSous17:"should match to password",
          SignUpSous18:"should be a valid phone number of the selected country",
          SignUpSous19:"should be a valid email",
          SignUpSous20:"this field is required",
          signUpLink:"Back to Registration",

          //NotFound
          NotFoud1:'OOPS! SOMETHING WENT WRONG',
          NotFoud2:"Sorry but the page you are looking for don't exist",
          NotFoud3:"Back to home page",

          //user
          user1: "Login",
          user2:  "Sign up",
          user3: "Sign out",

          //success
          sucessTitle1: 'Hey,Congratulations !!!',
          sucessTitle2: 'You have successfully credit mobile account',
          sucessTitle3: 'You have successfully credit buy crypayTitleto',
          sucessTitle4: 'You have successfully sold crypto',
          sucessTitle5: 'Please Rate us',
          sucessTitle6: 'Back to home page',

          //forget
          forgetTitle:"Forget Password",
          forgetSous1:"Lost your password? Please enter your email address. You will receive a link to create a new password via email.",
          forgetSous2:'Give your correct Email',
          forgetSous3:"Reset Password",
          forgetSous4:"Checking Your Email!",
          forgetSous5:"Dear customer, help us to help you!",
          forgetSous6:"Dear Client", 
          forgetSous7:"If you haven't received an email yet, please resend the email, to reset your password!",
          forgetSous8:"Resend Mail",
          forgetLink:"Back to Connexion",
          
          //reset
          resetTitle:"Reset Password",
          resetSous1:"Please enter your new password and confirm.",
          resetSous2:"The password must contain a minimum of eight characters including at least one digit", 
          resetSous3:"should match to password", 
          resetSous4:"Reset Password", 
          resetSous5:"Password", 
          resetSous6:" Confirm Password", 

          //pay
          payTitle:'The operation could be completed',
          paySous1:'Send back payment',
          paySous2:'You operation id is :',
          paySous3:'Use it for any claim',
          paySous4:'TRANSACTION IN PROGRESS',
          paySous5:' LANDS VERIFICATIONS ',
          paySous6:' Balance checking',
          paySous7:' User wallet checking',
          paySous8:'REQUEST OF MOBILE PAYMENT',
          paySous9:'please check payment request on your phone',
          paySous10:' If there is no request type ussd code',
          paySous11:' Please follow',
          paySous12:' To complete the payment',
          paySous13:'PREPARATION OF THE TRANSACTION ',
          paySous14:'SENDING FUNDS ',

          //notfound
          notFound1:'OOPS! SOMETHING WENT WRONG',
          notFound2:"Sorry but the page you are looking for don't exist",
          notFound3:'Back to home page',

          //more
          moreTitle1:"LEARN MORE",
          moreSous1:"About ",
          moreSous2:" Born from the dual observation of the explosion of Mobile Money and the demand for cryptocurrency in Africa, IPERCash is a platform essentially based on Blockchain and decentralized finance.",
          moreSous3:"Why the merger of Mobile Money and cryptocurrency is certainly the future of Africa and most likely of the rest of the world?  The banking rate in Africa is extremely low, particularly in sub-Saharan Africa . From where a dazzling penetration of Mobile Money making it possible to obtain an electronic wallet without constraints. Mobile Money works by USSD codes compatible with any phone, so this service does not require a sophisticated phone",
          moreSous4:"On the other hand, cryptocurrency, particularly ethereum, Bitcoin and usdt are also experiencing unexpected penetration in Africa.",
          moreSous5:" Indeed, the conditions dictated by local central banks and their depreciated local currencies are forcing local businesses and pushing them to turn to much more flexible cryptocurrency.",
          moreSous6:"We are therefore a platform for exchanges and interactions between Mobile Money and cryptocurrency and a necessity. <br/> We are an Internet cross platform and mobile application where users can:",
          moreSous7:"- Buy cryptocurrency by credit card and deposit it in a Mobile Money account in Africa.",
          moreSous8:"- Buy cryptocurrency to credit their electronic wallet;",
          moreSous9:"- Buy cryptocurrency by paying with Mobile Money",
          moreSous10:"- Withdraw cryptocurrency from his wallet and transfer it to his Mobile Money account.",
          moreSous11:"IPERCash is not a trader, IPERCash does not offer financial products or speculative investments but intends to participate in the increase in the volume of cryptocurrency exchanges in the world.",
          moreSous12:"We are therefore offering Africans the opportunity to purchase services and products in cryptocurrency, and vice versa, to be paid in cryptocurrency.",

        //data 
        dataTitle1: "About IPERCash",
        dataSous1: "We are interfaces between Mobile Money and Cryptocurrency ...",
        dataTitle2: "Make a deposit",
        dataSous2: "Create and validate your customer account to start, buy your crypto-currencies and deposit them in cash on a Mobile Money account",
        dataTitle3: "How to buy crypto",
        dataSous3: "Create and validate your customer account to start, debit your Mobile Money account or your bank card and receive the equivalent in crypto counterpart in your wallet.",
        dataTitle4:"How to sell crypto",
        dataSous4:"Create and validate your customer account to start, Withdraw your crypto-currencies by transferring them and receive the money instantly on a Mobile Money account ...",

        //complete
        completeTitle:"Please take a minute for :",
        completeSous1:"Rate us on",

        
        //case
          case1:"About IPERCash",
          case2:"The IPERCash platform is an interface between Mobile Money and cryptocurrency.",
          case3:"How to deposit",
          case4:"register and validate your customer account, identify yourself. Buy cryptocurrency and use it to fund a Mobile Money account.",
          case5:"How to buy crypto",
          case6:"Create and validate your customer account. Let us verify your identity. Pay with Mobile Money or credit card the crypto equivalent in your Wallet.",
          case7:"How to sell your crypto",
          case8:"Create and validate your customer account. Let us verify your identity. Send us your crypto and you will receive instantly the equivalent in your Mobile Money account.",

          //support 
          support:"Here you will find all the answers to your questions about IPERCash",
          support1:"Searching for answers...",


        //description
        descripTitle:"allez sur IPERCASH",
        descriptSous1:"About Us",
        descriptSous2:"Crypto Withdrawal",
        descriptSous3:"Purchase Crypto",
        descriptSous4:"Mobile Money Deposit",
        descriptSous6:"You will need to",
        descriptAjout:"Our mission as a digital exchange platform is to popularize and make accessible cryptocurrency in Sub-Saharan Africa. We invite you to contribute by being active on our exchange services and also by following us on social media.",
        descripTitleBody1:"Services offered on IPERCash",
        descripTitleBody2:"Summary of our service.",
        descripTitleBody3:"How To Withdraw Crypto",
        descripTitleBody4:"Fill out the form indicating:",
        descripTitleBody5:"your Wallet address, the amount to withdraw, the telephone number of the Mobile Money account to which you wish to withdraw the funds. Validate the form.",
        descripTitleBody6:"A summary of the transaction is displayed in a pop-up. If the data is correct, please confirm",
        descripTitleBody7:"A window appears showing the exact amount you wish to withdraw as well as the address of the IPERCash wallet",
        descripTitleBody8:"The following step, will require you to go to your wallet",
        descripTitleBody9:"- Either scan the transaction code from your wallet,",
        descripTitleBody10:"- Or copy the exact amount in BTC and paste it in your wallet for the sender. (Do not send more than the amount indicated for the transaction on the IPERCash platform. Do not send a different currency or token than BTC). Then, copy the  IPERCash wallet address and paste it into your Wallet for the recipient.",
        descriptionTitleBody53:" •	IMPORTANT: any error in the amount or wallet address will result in the loss of funds!",
        descripTitleBody11:"•	Here we have taken the example of a user wallet on the Coinbase platform. Once the transaction is complete in your wallet, return to the IPERCash site on this window to confirm your transfer by clicking on 'I have SENT'. You have 5 minutes for this.",
        descripTitleBody12:"Once confirmed, a window will appear asking you to wait for the confirmation of the transaction’s validation. This can take between 5 and 30 minutes depending on the size of the blockchain.",
        descripTitleBody13:"When you have carried out this transaction from your Wallet, you must return to our site on this window to confirm your sending by clicking on I HAVE SENT. You have 5 minutes for this.",
        descripTitleBody14:"Once confirmed, a window will appear asking you to wait for the confirmation of the transaction’s validation. This can take between 5 and 30 minutes depending on the size of the blockchain.",
        descripTitleBody15:"The CONGRATULATION page will be displayed to confirm the success of the transaction.",
        descripTitleBody16:"How to Buy Crypto",
        descripTitleBody17:"Fill out the form indicating:",
        descripTitleBody18:"A pop-up window will ask you to confirm the BTC address of your Wallet. This confirmation is requested because, according to the rules of the blockchain, any error in the destination Wallet address will automatically result in the loss of funds",
        descripTitleBody19:"Paste the destination Wallet address again in this window after ensuring its correctness. ",
        descripTitleBody20:"A page showing the progress of the transaction will open",
        descripTitleBody21:"You will receive an SMS from your mobile operator asking you to validate the withdrawal of funds from your Mobile Money account",
        descripTitleBody22:"Once the withdrawal is validated, the procedure will continue automatically until the funds are deposited into your wallet.  This can take 5 to 30 minutes depending on the blockchain congestion.",
        descripTitleBody23:"The CONGRATULATION page will be displayed to confirm the success of the transaction.",
        descripTitleBody24:"You will receive an SMS from your mobile operator asking you to validate the withdrawal of funds from your Mobile Money account.",
        descripTitleBody25:"Once the withdrawal is validated, the procedure will continue automatically until the funds are deposited into your wallet.  This can take 5 to 30 minutes depending on the blockchain congestion.",
        descripTitleBody26:"The CONGRATULATION page will be displayed to confirm the success of the transaction.",
        descripTitleBody27:"How to Make a Deposit",
        descripTitleBody28:"Procedure for making a deposit on Mobile Money",
        descripTitleBody29:"Go to CREDIT A MONEY MOBILE ACCOUNT page",
        descripTitleBody30:"Indicate the amount to be credited, the recipient's full name, country and phone number",
        descripTitleBody31:"Verify in the transaction summary that the costs are correct. Validate",
        descripTitleBody32:"You will be directed for payment by credit card on the page of our partner Mercuryo. Accept the terms and services and click on PAY",
        descripTitleBody33:"If you have already passed the identification (KYC procedure), enter your phone number, an SMS will be sent to you: ",
        descripTitleBody34:"After receiving the code by SMS, please enter it in the following window",
        descripTitleBody35:"Enter your credit card information or select a credit card from the ones previously saved in your account and validate",
        descripTitleBody36:"Enter the three-digit control number located on the back of the credit card",
        descripTitleBody37:"You will then be directed to your bank's website for the usual checks (e.g., two-step verification)",
        descripTitleBody38:"Once the payment has been validated by your bank, you will receive the confirmation of your payment in the following window,",
        // descripTitleBody39:"If you have already passed the identification (KYC procedure), enter your phone number, an SMS will be sent to you:",
        // descripTitleBody40:"When you have received the code by SMS, please enter it in the following window",
        // descripTitleBody41:"Enter your credit card information or select a credit card from the ones previously saved in your account and validate",
        // descripTitleBody42:"Enter the three-digit control number located on the back of the credit card",
        // descripTitleBody43:"You are then directed to your bank's website for the usual checks (usually a two-step check)",
        // descripTitleBody44:"Once the payment has been validated on your bank's website, you will receive confirmation of your payment in the following window",
        descripTitleBody45:"The payment will be received in the recipient’s Mobile Money account  pending the   blockchain’s process time (generally about 5 minutes yet in case of congestion, up to 30 minutes)",
        descripTitleBody46:"A summary of the transaction will be sent to the email address provided during the identification steps",
        descripTitleBody47:"We highly recommend saving the transaction number (Mercuryo ID) in case of a dispute.",
        descripTitleBody48:"To log in",
        descripTitleBody49:"To Our Platform",
        // descripTitleBody50:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, obcaecati id ipsumearum dolor impedit soluta enim ipsa corporis eos possimus cum pariatur ex, vero voluptatem laudantium sit adipisci ipsam?",
        descriptionBody51:"the amount you want to buy, your phone number, the address of your Bitcoin Wallet. Validate",
        descripTitleBody52:"It can last from 5 to 30 minutes depending on the blockchain congestion",

        //headHelp
        headHelp:"Go to",

        //valid 
        validTitle1: " Your account has been actived",
        validTitle2: "Login",
        validTitle3: "Your account cannot be activated",

        //widget
        widget:"your operation id is",

        //sumsub 
        sumsubText1:"The international policy against money laundering and terrorism requires identifying users.",
        sumsubText2:"We perform identity verification on the first transaction.",
 
    } 
}

export default english
