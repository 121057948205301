const french={ translation : {
 

        //coverModal
        coverModalTitle: 'TRANSACTION EN COURS',
        coverModalSous1: "L'identifiant de votre opération est :",
        coverModalSous2: "Utiliser le pour toute réclamation",
        coverModalSous3: 'Succès',
        coverModalSous4: "Opération terminée",
        coverModalSous5: "Inaliser l'opération sur le widget",

         //sellrypto 
         sellCrypto:'VENDRE VOTRE CRYPTO',
         sellCrypto2:'Cours de la Crypto',
         sellCrypto3:'Coindesk',
         sellCrypto4:' Fourni par',
         sellCrypto5:'Vendre',
         sellCrypto6:"Adresse du portefeuille de l'expéditeur",
         sellCrypto7:'Ce champ est requis',
         sellCrypto8:"Montant en XAF",
         sellCrypto9:"Le montant doit etre entre 3000 et 35000 XAF" ,
         sellCrypto10:"Montant en CRYPTO",
         sellCrypto11:"La valeur minimale est 0,00033",
         sellCrypto12:"Frais en XAF",
         sellCrypto13:"Numéro de téléphone portable du destinataire",
         sellCrypto14: "Numéro invalide",
         sellCrypto15:"Confirmer le téléphone portable",
         sellCrypto16:"Doit correspondre au numéro de téléphone" ,

         //sellModal
         sellModal:'Transaction confirmée',
         sellModal1:'Transaction trouvée mais pas encore confirmée en attente de confirmation',
         sellModal2:'Essayez avec un autre montant ou réessayez dans',
         sellModal3:'Annuler',
         sellModal4:"RÉSUMÉ DE L'OPÉRATION",
         sellModal5:'Modifier',
         sellModal6:'à',
         sellModal7:"Vous devez d'abord envoyer exactement",
         sellModal8:'Cliquez ensuite sur le bouton  \"J\'ai envoyé\" pour continuer.',
         sellModal9:'Scannez QrCode pour obtenir l\'adresse du portefeuille',
         sellModal10:'Voici votre identifiant d\'opération',
         sellModal11:'Annuler',
         sellModal12:'J\'ai envoyé',
         sellModal13:'envoyer',
         sellModal14:'De',
         sellModal15:'Recevoir',
         sellModal16:'Au numero',
         sellModal17:'Frais',
         sellModal18:'Confirmer',

         // buycryptoCard
        buyCryptoCardTitle: 'ACHETER CRYPTO AVEC CARTE DE CRÉDIT',
        buyCryptoCardSous1:'Cours de la Crypto',
        buyCryptoCardSous2:'Coindesk',
        buyCryptoCardSous3:'Fourni par',
        buyCryptoCardSous4:'Montant en XAF',
        buyCryptoCardSous5:"Montant en EUR",
        buyCryptoCardSous6:"Montant en BTC",
        buyCryptoCardSous7:"Adresse du portefeuille cryptographique",
        buyCryptoCardSous8:"An attente du taux...",
        buyCryptoCardSous9:"Acheter",
        buyCryptoCardSous10:"La valeur minimale est 16500 XAF",
        buyCryptoCardSous11:"La valeur minimale est de 25 EUR",
        buyCryptoCardSous12:"La valeur minimale est 0,00033",
        buyCryptoCardSous13:"Ce champ est requis" ,

        // buycryptoMobile
        buyCryptoMobileTitle: 'Vérification du portefeuille',
        buyCryptoMobileSous1:'Votre adresse de portefeuille',
        buyCryptoMobileSous2:'Revenir',
        buyCryptoMobileSous3:"ACHETER DE LA CRYPTO AVEC DU MOBILE MONEY",
        buyCryptoMobileSous4:'Coindesk',
        buyCryptoMobileSous5:'Cours de la crypto',
        buyCryptoMobileSous6:'Fourni par',
        buyCryptoMobileSous7:"Montant en XAF",
        buyCryptoMobileSous8:"Le montant doit etre entre 10000 et 35000 XAF",
        buyCryptoMobileSous9:"Montant en EUR",
        buyCryptoMobileSous10:"La valeur minimale est de 15,26 EUR",
        buyCryptoMobileSous11:"Montant en CRYPTO",
        buyCryptoMobileSous12:"La valeur minimale est 0,00033" ,
        buyCryptoMobileSous13:"Numéro de téléphone portable du destinataire",
        buyCryptoMobileSous14:"Numéro invalide",
        buyCryptoMobileSous15:"Confirmer le téléphone portable",
        buyCryptoMobileSous16:"Doit correspondre au numéro de téléphone",
        buyCryptoMobileSous17:"Adresse du portefeuille cryptographique",
        buyCryptoMobileSous18:"Ce champ est requis",
        buyCryptoMobileSous19:"En attente du taux...", 
        buyCryptoMobileSous20:"Acheter", 
        buyCryptoMobileSous21:"Collez votre adresse de portefeuille ici", 

         //chooses 
         choosesTitle: 'Choisissez votre mode de paiement',
         chosesSous1: 'Mobile Money',
         chosesSous2: 'Disponible dans les zones CEMAC et UMOA',
         chosesSous3: '',
         chosesSous4: 'Carte de Credit',

         //comingSoon
         comingSoonTitle: 'CETTE FONCTIONNALITÉ SERA BIENTÔT DISPONIBLE',
         comingSoonSous2: 'rejoindre la liste d\'attente',
         comingSoonSous3: 'Prévenez-moi',
         comingSoonSous4: 'Retour à la page d\'accueil',

         //footer
         footerSous1: ' Droit d\'auteur IPERCash. Tous droits réservés',
         footerSous2: 'Conçu par',
         footerSous3: 'IPERCash',
         footerSous4: 'Termes et conditions',
         footerSous5: 'Confidentialité',
         footerSous6: 'AML_Police',

         //form
         formSous1: 'NOM ET PRÉNOM',
         formSous2: 'E-MAIL',
         formSous3: 'MESSAGE',
         formSous4: 'Envoyer le message',
         formSous5: 'NOM',

         //Countries
         Coubtries1: 'Cameroun',
         Coubtries2: 'Sénégal',
         Coubtries3: 'Côte d\'Ivoire',
         Coubtries4: 'Mali',
         Coubtries5: 'Burkina',
         Coubtries6: 'Guinée',

         //Login
         LoginTitle:'Connectez-vous ',
         LoginSous1:'Authentifiez vous si vous avez déjà un compte, sinon créez un compte',
         LoginSous2:'Votre email est obligatoire !',
         LoginSous3:'Email',
         LoginSous4:"Mot de passe",
         LoginSous5:'Se souvenir',
         LoginSous6:'Connexion',
         LoginSous7:'Creer un Compte',
         LoginSous8:'Le mot de passe doit avoir au moins 8 caractères dont un chiffre',
         LoginSous9:"Mot de passe oublié",
         LoginSous10:"Le mot de passe doit avoir au moins 8 caractères dont un chiffre",
         LoginSous11:"Authentifiez vous si vous avez déjà un compte, sinon créez un compte",

          //SignUp
          SignUpTitle:"Inscrivez-Vous",
          SignUpSous1:'Si vous n’avez pas encore de compte, créez en un en suivant la procédure ci-dessous ',
          SignUpSous2:'Se souvenir',
          SignUpSous3:'Mot de passe oublié',
          SignUpSous4:'Inscrivez-Vous',
          SignUpSous5:"Nom",
          SignUpSous6:"Email",
          SignUpSous7:"Téléphone",
          SignUpSous8:"Mot de passe",
          SignUpSous9:"Confirmé le mot de passe" ,
          SignUpSous10:"Inscrivez-vous" ,
          SignUpSous11:"Verification De Votre Mail!" ,
          SignUpSous13:"Chère cliente, cher client" ,
          SignUpSous14:"Un mail vous a été envoyé, veuillez verifier dans la boite email et dans les spams! Sinon renvoyer le mail, pour la validation de votre inscription!" ,
          SignUpSous15:"Renvoyer le Mail" ,
          SignUpSous16:"Le mot de passe doit avoir au moins huit caractères dont un chiffre" ,
          SignUpSous17:"doit correspondre au mot de passe",
          SignUpSous18:"doit être un numéro de téléphone valide du pays sélectionné",
          SignUpSous19:"doit être un e-mail valide",
          SignUpSous20:"Ce champ est requis",
          signUpLink:"Retour à Inscription",

          //NotFound
          NotFoud1:'OUPS! Quelque chose a mal tourné',
          NotFoud2:"Désolé mais la page que vous recherchez n'existe pas",
          NotFoud3:"Retour à la page d'accueil",

          //user
          user1: "Connexion",
          user2:  "Inscription",
          user3: "Deconnection",

          //success
          sucessTitle1: 'Hé, félicitations !!!',
          sucessTitle2: 'Vous avez crédité votre compte mobile avec succès',
          sucessTitle3: 'Vous avez réussi à acheter de la crypto',
          sucessTitle4: 'Vous avez réussi à vendre de la crypto',
          sucessTitle5: "Nous evaluer sur",
          sucessTitle6: "Retour à la page d'accueil",


          //forget
          forgetTitle:"Mot de Passe Oublié",
          forgetSous1:"Mot de passe perdu ? Veuillez saisir votre identifiant ou adresse mail. Vous recevrez un lien pour créer un nouveau mot de passe par E-mail.",
          forgetSous2:"Donnez votre correct e-mail",
          forgetSous3:"Réinitialisation",
          forgetSous4:"Verification De Votre Mail!",
          forgetSous5:"Cher client, aidez-nous à vous aider !",
          forgetSous6:"Chère cliente, cher client",
          forgetSous7:"Si vous n'avez pas encore reçu de mail veuillez renvoyer le mail, pour reinitialiser votre mot de passe!",
          forgetSous8:"Renvoyer le Mail",
          forgetLink:"retour à la Connexion", 

          //reset
          resetTitle:"Reinitialisez votre Mot De Passe",
          resetSous1:"Veuillez saisir votre nouveau mot de passe et confirmer.",
          resetSous2:"Le mot de passe doit avoir au moins huit caractères dont un chiffre", 
          resetSous3:"le mot de passe doit correspondre", 
          resetSous4:"Réinitialiser", 
          resetSous5:"Mot de Passe",
          resetSous6:"Confirme Mot de Passe",

          //pay
          payTitle:"l'opération n'a pas pu se terminer",
          paySous1:'Renvoyer le paiement',
          paySous2:"votre identifiant d'opération est :",
          paySous3:"L'utiliser pour toute réclamation",
          paySous4:'TRANSACTION EN COURS',
          paySous5:'VÉRIFICATIONS DES pays  ',
          paySous6:' Vérification du solde',
          paySous7:"vérification du portefeuille de l'utilisateur",
          paySous8:'DEMANDE DE PAIEMENT MOBILE',
          paySous9:'veuillez vérifier la demande de paiement sur votre téléphone',
          paySous10:"Composez le code ussd de votre opérateur",
          paySous11:' Veuillez suivre',
          paySous12:' Pour terminer le paiement',
          paySous13:'PRÉPARATION DE LA TRANSACTION',
          paySous14:'ENVOYER DES FONDS',
           //notfound
          notFound1:'OUPS! Quelque chose a mal tourné',
          notFound2:"Désolé mais la page que vous recherchez n'existe pas",
          notFound3:"Retour à la page d'accueil",

          //more
          moreTitle1:"EN SAVOIR PLUS",
          moreSous1:"À propos d’",
          moreSous2:" Né du double constat de l’explosion du Mobile Money et de la demande de cryptomonnaie en Afrique, IPERCash est une plateforme essentiellement basée sur la Blockchain et la finance décentralisée.",
          moreSous3:"Pourquoi la fusion du Mobile Money et de la cryptomonnaie est certainement l’avenir de l’Afrique et très probablement du reste du monde ??  Le taux de bancarisation en Afrique est extrêmement bas, particulièrement en Afrique subsaharienne. D’où une fulgurante pénétration du Mobile Money permettant d’obtenir un portefeuille électronique sans contraintes. Le Mobile Money fonctionne par codes USSD compatibles  avec n’importe quel téléphone, ce service ne nécessite donc pas de téléphone sophistiqué",
          moreSous4:"D’autre part, la cryptomonnaie, particulièrement l'ethereum,le Bitcoin et l'usdt connaîssent également une pénétration inattendue en Afrique.",
          moreSous5:" En effet, les conditions dictées par les banques centrales locales et leurs monnaies locales dépréciées contraignent les entreprises locales et les poussent à se tourner vers la cryptomonnaie bien plus souple.",
          moreSous6:"Nous sommes donc une plateforme d’échanges et d’interactions entre le Mobile Money et la cryptomonnaie et une nécessité. Nous sommes Une cross plate forme Internet et une application mobile  où les utilisateurs peuvent :",
          moreSous7:"- Acheter de la cryptomonnaie par carte de crédit et la déposer sur un compte Mobile Money en Afrique.",
          moreSous8:"- Acheter de la cryptomonnaie pour créditer leur  portefeuille électronique ;",
          moreSous9:"- Acheter de la cryptomonnaie en payant avec Mobile Money",
          moreSous10:"- Retirer de la cryptomonnaie de son portefeuille et la transférer dans son compte Mobile Money.",
          moreSous11:"IPERCash n’est pas un trader, IPERCash ne propose ni des produits financiers, ni  d’investissements spéculatifs mais entend participer à l’augmentation du volume des échanges en cryptomonnaie dans le monde.",
          moreSous12:"Nous offrons donc aux Africains la possibilité  d’acheter des services et des produits en cryptomonnaie,et inversement, d’être payé en cryptomonnaie.",

          //data 
          dataTitle1: "A propos de IPERCash",
          dataSous1: "Nous sommes interfaces entre Mobile Money et la Cryptomonnaie...",
          dataTitle2: "Effectuer un depôt",
          dataSous2: "Créez et validez votre compte client pour commencer , acheter vos crypto-monnaies et deposer les en contre-partie argent sur un compte Mobile Money",
          dataTitle3: "Comment acheter la crypto",
          dataSous3: "Créez et validez votre compte client pour commencer , debiter votre compte Mobile Money ou votre carte bancaire et recevez l'equivalent en contre-partie crypto dans votre wallet.",
          dataTitle4:"Comment vendre la crypto",
          dataSous4:"Créez et validez votre compte client pour commencer ,Retirez vos crypto-monnaies en les transférant et recevez l'argent instantanément sur un compte Mobile Money...",

           //complete
            completeTitle:"Veuillez prendre une minute pour :",
            completeSous1:"Nous évaluez sur",

            //case
            case1:"A propos d’IPERCash",
            case2:"La plateforme d’IPERCash est une interface entre le Mobile Money et la cryptomonnaie.",
            case3:"Comment effectuer un dépôt",
            case4:"Créez et validez votre compte client, faites vous identifier. Achetez de la cryptomonnaie et utilisez-la pour créditer un compte Mobile Money.",
            case5:"Comment acheter de la crypto",
            case6:"Créez et validez votre compte client, faites vous identifier. Payer par Mobile Money ou par carte bancaire et recevez l’équivalent en crypto dans votre Wallet.",
            case7:"Comment vendre sa crypto",
            case8:"Créez et validez votre compte client, faites vous identifier. Transférez nous votre cryptomonnaie et vous recevrez l’équivalent instantanément dans votre compte Mobile Money.",

            //support 
            support:"Vous trouverez ici toutes les réponses à vos questions concernant IPERCash",
            support1:"Rechercher des réponses...",
            
            //description
            descripTitle:"allez sur IPERCASH",
            descriptSous1:"A Propos de Nous",
            descriptSous2:"Retrait Crypto",
            descriptSous3:"Achat Crypto",
            descriptSous4:"Dépôt Mobile Money",
            descriptSous6:"Vous devez ",
            descriptAjout:"Notre mission en tant que plateforme d'échange numérique est de populariser et de rendre accessible les crypto-monnaies en Afrique subsaharienne. Nous vous invitons à contribuer en étant actif sur nos services d'échange et aussi en nous suivant sur les médias sociaux.",
            descripTitleBody1:"Les Services Offerts sur IPERCash",
            descripTitleBody2:"Recapitulatif de notre service.",
            descripTitleBody3:"Comment retirer de la Crypto ",
            descripTitleBody4:"Remplissez le formulaire en indiquant :",
            descripTitleBody5:"votre adresse Wallet, le montant à retirer, le numéro de téléphone du compte Mobile Money vers lequel vous souhaitez retirer les fonds. Validez le formulaire.  ",
            descripTitleBody6:"Un récapitulatif de la transaction s’affiche en pop-up. Si les données sont exactes, veuillez confirmer",
            descripTitleBody7:"Apparaît une fenêtre où s’affichent le montant exact que vous souhaitez retirer ainsi que l’adresse du wallet d’IPERCash",
            descripTitleBody8:"A cette étape, il faut vous rendre dans votre wallet",
            descripTitleBody9:"- soit scanner ce code depuis votre wallet,",
            descripTitleBody10:"- soit copier ces données (montant exact en BTC et adresse Wallet,d’IPERCash) afin d’effectuer un envoi de bitcoins au montant exact à l’adresse de IPERCash (toute erreur de montant ou d’adresse wallet entraînera la perte des fonds). Donc copiez le montant en BTC et allez le coller dans votre wallet pour un envoi. Copiez également l’adresse Wallet de IPERCash afin de la coller dans votre Wallet pour l’adresse de destination de l’envoi.  ",
            descripTitleBody11:"Nous avons pris ici l’exemple d’un utilisateur possédant son Wallet sur la plateforme Coinbase. Lorsque vous aurez effectué cette transaction depuis votre Wallet, vous devez revenir sur notre site sur cette fenêtre confirmer votre envoi en cliquant sur I HAVE SENd. Vous avez 5 mn pour cela",
            descripTitleBody12:"Une fois confirmé, une fenêtre s’affiche demandant d’attendre la confirmation de la validation. Ceci peut durer entre 5 mn et 30 mn en fonction de l’encombrement de la blockchain",
            descriptionTitleBody53:" • IMPORTANT : toute erreur dans le montant ou l'adresse du portefeuille entraînera la perte de fonds !",
            descripTitleBody13:"Lorsque vous aurez effectué cette transaction depuis votre Wallet, vous devez revenir sur notre site sur cette fenêtre confirmer votre envoi en cliquant sur I HAVE SENd.  Vous avez 5 mn pour cela.",
            descripTitleBody14:"Une fois confirmé, une fenêtre s’affiche demandant d’attendre la confirmation de la validation. Ceci peut durer entre 5 mn et 30 mn en fonction de l’encombrement de la blockchain.",
            descripTitleBody15:"La page CONGRATULATION s’affiche pour confirmer le succès de la transaction.",
            descripTitleBody16:"Comment Acheter une Crypto",
            descripTitleBody17:"Remplissez le formulaire en indiquant :",
            descripTitleBody18:"Une fenêtre pop-up vous demande de confirmer l’adresse BTC de votre Wallet. Cette confirmation est demandée car, selon les règles de la blockchain, toute erreur dans l’adresse Wallet de destination entraînera automatiquement la perte des fonds",
            descripTitleBody19:"Collez donc à nouveau dans cette fenêtre l’adresse Wallet de destination si vous en êtes sûr",
            descripTitleBody20:"La page indiquant la progression de la transaction s’ouvre",
            descripTitleBody21:"Vous recevrez un sms de votre opérateur mobile vous demandant de valider le retrait des fonds de votre compte Mobile Money",
            descripTitleBody22:"Une fois le retrait validé, la procédure continue automatiquement jusqu’au dépôt des fonds dans votre wallet. Elle peut durer de 5 à 30 mn en fonction de la congestion de la blockchain",
            descripTitleBody23:"La page CONGRATULATION s’affiche pour confirmer le succès de la transaction",
            descripTitleBody24:"Vous recevrez un sms de votre opérateur mobile vous demandant de valider le retrait des fonds de votre compte Mobile Money",
            descripTitleBody25:"Une fois le retrait validé, la procédure continue automatiquement jusqu’au dépôt des fonds dans votre wallet",
            descripTitleBody26:"La page CONGRATULATION s’affiche pour confirmer le succès de la transaction",
            descripTitleBody27:"Comment Effectuer un Depot",
            descripTitleBody28:"Procédure pour effectuer un dépôt sur Mobile Money",
            descripTitleBody29:"Allez sur la page CREDITER UN COMPTE MOBILE MONEY",
            descripTitleBody30:"Indiquez le montant à créditer, le nom complet du destinataire, son pays et son numéro de téléphone",
            descripTitleBody31:"Vérifiez dans le sommaire de la transaction que les couts sont corrects. Validez",
            descripTitleBody32:"Vous êtes dirigé pour le paiement par carte de crédit sur la page de notre partenaire Mercuryo. Acceptez les termes et services et cliquez sur PAY",
            descripTitleBody33:"Si vous avez déjà passé l’indentification (procédure KYC), rentrez votre numéro de téléphone, un sms vous sera envoyé : ",
            descripTitleBody34:"Lorsque vous avez reçu le code par SMS, veuillez le rentrer dans la fenêtre suivante",
            descripTitleBody35:"Choisissez parmi les differents moyens de paymens et les cartes de crédit que vous avez enregistrées dans votre compte celle que vous souhaitez utiliser pour procéder au paiement et validez",
            descripTitleBody36:"Entrez le numéro de contrôle à trois chiffres situé au dos de la carte de crédit",
            descripTitleBody37:"Vous êtes ensuite dirigé vers le site de votre banque pour les vérifications habituelles (généralement contrôle en deux étapes)"  ,
            descripTitleBody38:"Une fois le paiement validé sur le site de votre banque, vous recevrez la confirmation de votre paiement sur la fenêtre suivante",
            descripTitleBody39:"Si vous avez déjà passé l’indentification (procédure KYC), rentrez votre numéro de téléphone, un sms vous sera envoyé :",
            descripTitleBody40:"Lorsque vous avez reçu le code par SMS, veuillez le rentrer dans la fenêtre suivante ",
            descripTitleBody41:"Choisissez parmi les differents moyens de paymens et des cartes de crédit que vous avez enregistrées dans votre compte celle que vous souhaitez utiliser pour procéder au paiement et validez",
            descripTitleBody42:"Entrez le numéro de contrôle à trois chiffres situé au dos de la carte de crédit",
            descripTitleBody43:"Vous êtes ensuite dirigé vers le site de votre banque pour les vérifications habituelles (généralement contrôle en deux étapes)"  ,
            descripTitleBody44:"Une fois le paiement validé sur le site de votre banque, vous recevrez la confirmation de votre paiement sur la fenêtre suivante",
            descripTitleBody45:"Le paiement sera reçu dans le compte Mobile Money de destination le temps nécessaire à la blockchain (généralement 5 mn mais en cas de congestion cela peut durer jusqu’à 30 mn).   ",
            descripTitleBody46:"Un mail récapitulatif de la transaction vous est envoyé dans la boite mail que vous avez communiquée au moment de l’identification",
            descripTitleBody47:"Veuillez mémoriser le numéro de la transaction (Mercuryo ID) en cas de contestations. Merci",
            descripTitleBody48:"Se Connecter",
            descripTitleBody49:"A Notre Plateforme",
            descripTitleBody50:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, obcaecati id ipsumearum dolor impedit soluta enim ipsa corporis eos possimus cum pariatur ex, vero voluptatem laudantium sit adipisci ipsam?",
            descriptionBody51:"le montant que vous souhaitez acheter, votre numéro de téléphone, l’adresse de votre Wallet en Bitcoins. Validez",
            descripTitleBody52:"Elle peut durer de 5 à 30 mn en fonction de la congestion de la blockchain",

            //headHelp
            headHelp:"Allez sur",

            //valid 
            validTitle1: "Votre compte a été activé",
            validTitle2: "Connexion",
            validTitle3: "Votre compte ne peut pas être activé",
            
            //widget
            widget:"L'id de votre identifiant est",

            //sumsub 
            sumsubText1:"Pour la sécurité de vos transactions, uniquement lors de votre première opération vous serez identifié par votre carte d’identité ou votre passeport et effectuerez une reconnaissance faciale.",
            sumsubText2:"Cette procédure est imposée par la politique internationale contre le terrorisme et le blanchiment d’argent.",
 

    } 
}

export default french
